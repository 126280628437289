import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import { ZipCodeTW } from 'zipcode-tw-react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Link,useNavigate } from 'react-router-dom';
import HouseSearch from '../search/HouseSearch';


const API_PATH = process.env.REACT_APP_API_PATH;

const columnVisibilityModel = {
  id:false
}

export default function HouseList() {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [filterRows,setFilterRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [zipCodeInfo, setZipCodeInfo] = useState({
    county: "",
    district: "",
    zipCode: ""
});
  const [filter,setFilter] = useState({
    HouseName:'',
    City:'',
    District:'',
    Address:'',
    Rent:null,
    BusinessName:'',
    DeveloperName:'',
    Start:null,
    End:null,
    Landlord:''
  });
  const [request, setRequest] = useState({
    Id: 0,
    Water: '',
    ObjectStatus: '空白',
    ObjectNo: '',
    DevelopmentDate: '', // 根據需要調整日期格式
    BusinessPersonId: 0,
    BusinessPersonName: '',
    Dialogue: '',
    Landlord: '',
    Phone: '',
    City: '',
    District: '',
    Address: '',
    Rent: 0,
    ContainService: '',
    Pattern: '',
    Area: 0,
    Type: '',
    Situation: '',
    Electricity: '',
    ManagementFee: 0,
    OpenFire: -1,
    Pet: -1,
    ObjectName: '',
    ValidDate: '', // 根據需要調整日期格式
    Web591Url: '',
    DevelopmentPersonId: 0,
    DevelopmentPersonName: '',
    ServiceFee: 0,
    Web591Date: '', // 根據需要調整日期格式
    RentSignDate: '', // 根據需要調整日期格式
    ObjectEvaluate: '',
    TotalHigh:'',
    Memo: '尚無備註',
  });
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'ObjectStatus',
      headerName: '物件狀態',
      width: 100,
      editable: false,
      renderCell: (params) => {
        let color;
        let message;
        switch (params.value) {
          case '成交':
            color = 'red'; // 成交状态的文字颜色
            message = '已出租';
            break;
          case '下架':
            color = 'orange'; // 下架状态的文字颜色
            message = '已下架';
            break;
          default:
            color = 'green'; // 默认或空白状态的文字颜色
            message = '出租中';
        }
    
        return <span style={{ color }}>{message}</span>;
      },
    },
    {
      field: 'ObjectNo',
      headerName: '物件編號',
      sortable: false,
      width: 100,
    },    
    {
      field: 'DevelopmentPersonName',
      headerName: '開發員名稱',
      width: 110,
      editable: false,
    },
    {
      field: 'BusinessPersonName',
      headerName: '業務名稱',
      sortable: false,
      width: 100,
    },
    {
      field: 'Web591Url',
      headerName: '591網址',
      sortable: false,
      width: 100,
    },
    {
      field: 'Type',
      headerName: '型態',
      sortable: false,
      width: 100,
    },
    {
      field: 'City',
      headerName: '縣市',
      sortable: false,
      width: 100,
    },
    {
      field: 'District',
      headerName: '行政區',
      sortable: false,
      width: 100,
    },
    {
      field: 'Address',
      headerName: '地址',
      sortable: false,
      width: 100,
    },
    {
      field: 'TotalHigh',
      headerName: '總樓層',
      sortable: false,
      width: 70,
    },
    {
      field: 'Pattern',
      headerName: '格局',
      sortable: false,
      width: 100,
    },
    {
      field: 'Area',
      headerName: '坪數',
      sortable: false,
      width: 100,
    },
    {
      field: 'OpenFire',
      headerName: '可否開伙',
      sortable: false,
      width: 100,
      valueFormatter: (params) => params.value === 1? '可':'否', 
    },
    {
      field: 'Pet',
      headerName: '可否寵物',
      sortable: false,
      width: 100,
      valueFormatter: (params) => params.value === 1? '可':'否', 
    },

    {
      field: 'Rent',
      headerName: '租金',
      sortable: false,
      width: 100,
    },
    {
      field: 'ManagementFee',
      headerName: '管理費',
      sortable: false,
      width: 100,
    },
    {
      field: 'ContainService',
      headerName: '含',
      sortable: false,
      width: 100,
    },
    {
      field: 'Dialogue',
      headerName: '對話',
      sortable: false,
      width: 100,
    },
    {
      field: 'Electricity',
      headerName: '電費',
      sortable: false,
      width: 100,
    },
    {
      field: 'Water',
      headerName: '水費',
      sortable: false,
      width: 100,
    },
    {
      field: 'Landlord',
      headerName: '房東',
      sortable: false,
      width: 100,
    },
    {
      field: 'Phone',
      headerName: '房東手機',
      sortable: false,
      width: 100,
    },
    {
      field: 'ServiceFee',
      headerName: '服務費',
      width: 110,
      editable: false,
    },
    {
      field: 'Web591Date',
      headerName: '591上架日',
      width: 100,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${  date.getMinutes()}`).slice(-2); 
        const hours = (`0${  date.getHours()}`).slice(-2);     
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} `;
      }, 
    },
    {
      field: 'RentSignDate',
      headerName: '簽約日',
      width: 100,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${  date.getMinutes()}`).slice(-2); 
        const hours = (`0${  date.getHours()}`).slice(-2);     
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      }, 
    },
    {
      field: 'Operation',
      headerName: '操作',
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton aria-label="delete" onClick={() => handleOpenForm('edit',params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDeleteSubmit(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
  
      ),
    }
  ];
  const handleDeleteSubmit = async (id) => {
    // 在删除之前先弹出确认框
    const confirmed = window.confirm("您確定要刪除嗎？");
    if (!confirmed) {
        return; // 如果用户取消了操作，则直接返回，不执行删除操作
    }

    const config = {
        headers: {
            'X-Ap-AdminId': `${sessionStorage.getItem('Id')}`,
            'X-Ap-StoreId': `${sessionStorage.getItem('StoreId')}`,
            'X-Ap-AdminToken': `${sessionStorage.getItem('Token')}`,
        }
    };

    try {
        const response = await axios.delete(`${API_PATH}/house/house`, {
            ...config,
            params: { id }
        });

        if (response.status === 200) {
            fetchData();
        }
    } catch (error) {
        console.error("Error deleting record:", error);
        alert('請確認資料型態有無錯誤')
    }
}

  const handleSubmit = async () => {
    const config = {
      headers: {
        'X-Ap-AdminId': `${sessionStorage.getItem('Id')}`,
        'X-Ap-StoreId': `${sessionStorage.getItem('StoreId')}`,
        'X-Ap-AdminToken': `${sessionStorage.getItem('Token')}`,
      }
    };     
    try {
        const response = await axios.post(`${API_PATH}/house/house`, request, config);
        if (response.status === 200) {
          alert('成功');
          handleCloseForm();
          fetchData();
        }
    } catch (error) {
        console.error("Error logging in:", error);
        alert('請確認資料型態有無錯誤')
    }                    
  }
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/house/admingethouse`, {
        headers: {
          'X-Ap-AdminId': `${sessionStorage.getItem('Id')}`,
          'X-Ap-StoreId': `${sessionStorage.getItem('StoreId')}`,
          'X-Ap-AdminToken': `${sessionStorage.getItem('Token')}`,
        }
      });
  
      if (response.status === 200) { 
        setRows(response.data);
        setFilterRows(response.data);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // Unauthorized
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };

  const handleZipCodeChange = (data) => {
    setZipCodeInfo(prev => ({
        ...prev,
        [data.countyFieldName]: data.countyValue,
        [data.districtFieldName]: data.districtValue,
        [data.zipFieldName]: data.zipValue,
    }));
    console.log(data);
};
const handleZipCodeNotExists = (data) => {
    alert('郵遞區號不存在');
};

  const fetchFilterData = async () => {
    console.log(zipCodeInfo);  
    try {
      // 创建 URLSearchParams 实例
      const queryParams = new URLSearchParams();
  
      // 为每个过滤器参数添加条件
      if (zipCodeInfo.county) queryParams.append('city', zipCodeInfo.county);
      if (filter.HouseName) queryParams.append('house', filter.HouseName);
      if (zipCodeInfo.district) queryParams.append('district', zipCodeInfo.district);
      if (filter.Address) queryParams.append('address', filter.Address);
      if (filter.Rent !== null) queryParams.append('rent', filter.Rent);
      if (filter.BusinessName) queryParams.append('businessMan', filter.BusinessName);
      if (filter.DeveloperName) queryParams.append('developer', filter.DeveloperName);
      if (filter.Start !== null) queryParams.append('start', filter.Start);
      if (filter.End !== null) queryParams.append('end', filter.End);
      if (filter.Landlord) queryParams.append('landlord', filter.Landlord);
  
      // 构建完整的请求 URL
      const requestUrl = `${API_PATH}/house/admingethouse?${queryParams.toString()}`;
  
      // 发送请求
      const response = await axios.get(requestUrl, {
        headers: {
          'X-Ap-AdminId': sessionStorage.getItem('Id'),
          'X-Ap-StoreId': sessionStorage.getItem('StoreId'),
          'X-Ap-AdminToken': sessionStorage.getItem('Token'),
        }
      });
  
      // 处理响应
      if (response.status === 200) {
        setRows(response.data);
        setFilterRows(response.data);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // Unauthorized
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };
  
  const handleOpenForm = async (model,row) => {
    console.log(model)
    console.log(row)
    if(model === 'create'){
      setRequest({
        Id: 0,
        ObjectStatus: '空白',
        ObjectNo: '',
        DevelopmentDate: '', // 根據需要調整日期格式
        BusinessPersonId: 0,
        BusinessPersonName: '',
        Dialogue: '',
        Landlord: '',
        Phone: '',
        City: '',
        District: '',
        Address: '',
        Rent: 0,
        ContainService: '',
        Pattern: '',
        Area: 0,
        Type: '',
        Situation: '',
        Electricity: '',
        ManagementFee: 0,
        OpenFire: -1,
        Pet: -1,
        ObjectName: '',
        ValidDate: '', // 根據需要調整日期格式
        Web591Url: '',
        DevelopmentPersonId: 0,
        DevelopmentPersonName: '',
        ServiceFee: 0,
        Web591Date: '', // 根據需要調整日期格式
        RentSignDate: '', // 根據需要調整日期格式
        ObjectEvaluate: '',
        Memo: '尚無備註',
      });
    }
    if(model === 'edit'){
      setRequest({
        Id: row.id,
        ObjectStatus: row.ObjectStatus,
        ObjectNo: row.ObjectNo,
        DevelopmentDate: row.DevelopmentDate, // 根據需要調整日期格式
        BusinessPersonId: row.BusinessPersonId,
        BusinessPersonName:row.BusinessPersonName,
        Dialogue: row.Dialogue,
        Landlord: row.Landlord,
        Phone: row.Phone,
        City: row.City,
        District: row.District,
        Address:row.Address,
        Rent: row.Rent,
        ContainService: row.ContainService,
        Pattern: row.Pattern,
        Area: row.Area,
        Type: row.Type,
        Situation: row.Situation,
        Electricity: row.Electricity,
        ManagementFee: row.ManagementFee,
        OpenFire: row.OpenFire,
        Pet: row.Pet,
        ObjectName: row.ObjectName,
        ValidDate: row.ValidDate, // 根據需要調整日期格式
        Web591Url: row.Web591Url,
        DevelopmentPersonId:row.DevelopmentPersonId,
        DevelopmentPersonName: row.DevelopmentPersonName,
        ServiceFee: row.ServiceFee,
        Web591Date: row.Web591Date, // 根據需要調整日期格式
        RentSignDate: row.RentSignDate, // 根據需要調整日期格式
        ObjectEvaluate: row.ObjectEvaluate,
        Memo: row.Memo,
      });
    }
    setOpen(true);
  };

  const handleCloseForm = async () => {
    setOpen(false);
  };

  const handleInputChange = (event, propertyName) => {
    const value = event.target.value;
    setRequest((prevData) => ({
      ...prevData,
      [propertyName]: value,
    }));
  };

  const handleFilterChange = (event, propertyName) => {
    const value = event.target.value;
    setFilter((prevData) => ({
      ...prevData,
      [propertyName]: value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []); 


  return (
    <>
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                    物件列表 
                </Typography>
            </Grid>                
          {/* <Grid item xs={12} style={{display:'flex',justifyContent:'center',marginTop:'5%'}}>      
              <ZipCodeTW
                  displayType="text"
                  countyValue={zipCodeInfo.county}
                  districtValue={zipCodeInfo.district}
                  zipCodeValue={zipCodeInfo.zipCode}
                  handleChangeCounty={handleZipCodeChange}
                  handleChangeDistrict={handleZipCodeChange}
                  handleChangeZipCode={handleZipCodeChange}
                  handleBlurZipCode={handleZipCodeChange}
                  handleZipCodeNotExists={handleZipCodeNotExists}
                  zipCodePositionLast={false}
              />
          </Grid>      */}
          <Grid item xs={9} style={{display:'flex',justifyContent:'center'}}>      
              <TextField id="standard-basic" label="地址" 
              style={{width:'90%'}}
              size='small'
              variant="standard" 
                  value={filter.Address}
                  onChange={(e) => handleFilterChange(e, 'Address')}/>
          </Grid>
          <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
              <TextField id="standard-basic" label="物件代號" 
              style={{width:'90%'}}
              variant="standard"   
              size='small'
                  value={filter.DeveloperName}
                  onChange={(e) => handleFilterChange(e, 'DeveloperName')}/>
          </Grid>  
          <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
              <TextField id="standard-basic" label="物件名稱" 
              variant="standard" 
              size='small'
              value={filter.HouseName}
              onChange={(e) => handleFilterChange(e, 'HouseName')}/>
          </Grid> 
          <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
              <TextField id="standard-basic" label="租金" variant="standard"     
              size='small'
                  value={filter.Rent === null?'':filter.Rent}
                  onChange={(e) => handleFilterChange(e, 'Rent')}/>
          </Grid> 
          <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
              <TextField id="standard-basic" label="業務名稱" 
              variant="standard"     
              size='small'
                  value={filter.BusinessName}
                  onChange={(e) => handleFilterChange(e, 'BusinessName')}/>
          </Grid> 
          <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
              <TextField id="standard-basic" label="房東名稱"
                variant="standard"
              size='small'
                  value={filter.Landlord}
                  onChange={(e) => handleFilterChange(e, 'Landlord')}/>
          </Grid> 
              {/* <Grid item xs={6} style={{display:'flex',justifyContent:'center'}}>      
                  <TextField id="standard-basic" label="搜尋起始日" variant="standard"                 
                      value={filter.Start=== null?'':filter.Start}
                      placeholder='YYYY-MM-DD'
                      size='small'
                      onChange={(e) => handleFilterChange(e, 'Start')}/>
              </Grid> 
              <Grid item xs={6} style={{display:'flex',justifyContent:'center'}}>      
                  <TextField id="standard-basic" label="搜尋截止日" variant="standard"                 
                      value={filter.End=== null?'':filter.End}
                      placeholder='YYYY-MM-DD'
                  size='small'
                      onChange={(e) => handleFilterChange(e, 'End')}/>
              </Grid> */}
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}/>
            <HouseSearch rows={rows} setFilterRows={setFilterRows}/>
            <Button onClick={() =>handleOpenForm('create')}>新增物件</Button>  
            <Button onClick={() =>setFilter({
                HouseName:'',
                City:'',
                District:'',
                Address:'',
                Rent:null,
                BusinessName:'',
                DeveloperName:'',
                Start:null,
                End:null,
                Landlord:''
              })}>清除搜尋條件</Button>  
            <Button onClick={fetchFilterData}>搜尋</Button>  

      </Grid>
      <DataGridPro
        rows={filterRows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
      />
    </Box>
    <Dialog open={open} onClose={handleCloseForm}>
        <DialogTitle>{request.Id === 0?'新增物件資料':'修改物件資料'}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
          <FormControl style={{marginRight:'5%'}}>
            <FormLabel id="demo-row-radio-buttons-group-label">物件狀態</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={request.ObjectStatus}
              onChange={(e) => handleInputChange(e, 'ObjectStatus')}
            >
              <FormControlLabel value="空白" control={<Radio />} label="空白" />
              <FormControlLabel value="成交" control={<Radio />} label="成交" />
              <FormControlLabel value="下架" control={<Radio />} label="下架" />
            </RadioGroup>
          </FormControl>
          {/* <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="物件名稱"
            value={request.ObjectName}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'ObjectName')}
          /> */}
          <TextField
            autoFocus
            margin="dense"
            label="物件編號"
            variant="standard"
            value={request.ObjectNo}
            onChange={(e) => handleInputChange(e, 'ObjectNo')}
          />
          <TextField           
            required
            margin="dense"
            id="email"
            label="開發日期"
            variant="standard"
            value={request.DevelopmentDate}
            placeholder='YYYY-MM-DD'
            onChange={(e) => handleInputChange(e, 'DevelopmentDate')}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="password"
            label="業務編號(不可編輯)"
            fullWidth
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
            value={request.BusinessPersonId}
          /> */}
          <TextField           
            required
            margin="dense"
            id="email"
            label="總樓層"
            variant="standard"
            value={request.TotalHigh}
            onChange={(e) => handleInputChange(e, 'TotalHigh')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="createDate"
            label="業務名稱"
            value={request.BusinessPersonName}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'BusinessPersonName')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="startDate"
            variant="standard"
            label="對話"
            value={request.Dialogue}
            onChange={(e) => handleInputChange(e, 'Dialogue')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="房東名稱"
            value={request.Landlord}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'Landlord')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="房東手機"
            value={request.Phone}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'Phone')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="物件縣市"
            value={request.City}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'City')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="物件行政區"
            value={request.District}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'District')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="物件地址"
            value={request.Address}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'Address')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="物件租金"
            value={request.Rent}
            variant="standard"
            placeholder='請輸入純數字'
            onChange={(e) => handleInputChange(e, 'Rent')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="含"
            value={request.ContainService}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'ContainService')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="格局"
            value={request.Pattern}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'Pattern')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="坪數"
            value={request.Area}
            variant="standard"
            placeholder='請輸入純數字'
            onChange={(e) => handleInputChange(e, 'Area')}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="型態"
            value={request.Type}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'Type')}
          /> */}
          {/* <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="現況"
            value={request.Situation}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'Situation')}
          /> */}
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="電費"
            value={request.Electricity}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'Electricity')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="水費"
            value={request.Water}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'Water')}
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="管理費"
            value={request.ManagementFee}
            variant="standard"
            placeholder='請輸入純數字'
            onChange={(e) => handleInputChange(e, 'ManagementFee')}
          />
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">開伙</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={request.OpenFire}
              onChange={(e) => handleInputChange(e, 'OpenFire')}
            >
              <FormControlLabel value={1} control={<Radio />} label="可" />
              <FormControlLabel value={-1} control={<Radio />} label="不可" />
              <FormControlLabel value={0} control={<Radio />} label="可電" />
            </RadioGroup>
          </FormControl>
          <FormControl style={{ marginLeft: '10%' }}>
            <FormLabel id="demo-row-radio-buttons-group-label">寵物</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={request.Pet}
              onChange={(e) => handleInputChange(e, 'Pet')}
            >
              <FormControlLabel value={1} control={<Radio />} label="可" />
              <FormControlLabel value={0} control={<Radio />} label="可貓" />
              <FormControlLabel value={-1} control={<Radio />} label="不可" />
            </RadioGroup>
          </FormControl>
          {/* <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="有效日期"
            required
            value={request.ValidDate}
            variant="standard"
            placeholder='YYYY-MM-DD'
            onChange={(e) => handleInputChange(e, 'ValidDate')}
          /> */}
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="591網址"
            value={request.Web591Url}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'Web591Url')}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="開發者編號(不可編輯)"
            value={request.DevelopmentPersonId}
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          /> */}
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="開發者名稱"
            value={request.DevelopmentPersonName}
            variant="standard"
            onChange={(e) => handleInputChange(e, 'DevelopmentPersonName')}           
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="服務費"
            value={request.ServiceFee}
            variant="standard"
            placeholder='請輸入純數字'
            onChange={(e) => handleInputChange(e, 'ServiceFee')}           
          />
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="591上架日"
            required
            value={request.Web591Date}
            variant="standard"
            placeholder='YYYY-MM-DD'
            onChange={(e) => handleInputChange(e, 'Web591Date')}           
          />          
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            required
            label="簽約日"
            value={request.RentSignDate}
            variant="standard"
            placeholder='YYYY-MM-DD'
            onChange={(e) => handleInputChange(e, 'RentSignDate')}           
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="評估"
            value={request.ObjectEvaluate}
            variant="standard"
            aria-readonly
            onChange={(e) => handleInputChange(e, 'ObjectEvaluate')}           
          /> */}
          <TextField
            autoFocus
            margin="dense"
            id="endDate"
            label="備註"
            value={request.Memo}
            variant="standard"
            aria-readonly
            onChange={(e) => handleInputChange(e, 'Memo')}           
          />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>取消</Button>
          <Button onClick={handleSubmit}>送出</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


// function Filter() {
//   const [open, setOpen] = useState(false);

//   const handleCloseForm = async () => {
//     setOpen(false);
//   };
//   const handleOpenForm = async () => {
//     setOpen(true);
//   };
//   return (
//   );
// }