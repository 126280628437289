// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const AdminConfig = [
  {
    title: '業務列表',
    path: '/admin/businessmans',
    icon: icon('ic_analytics')
  },
  {
    title: '開發列表',
    path: '/admin/developers',
    icon: icon('ic_analytics')
  },
  {
    title: '物件列表',
    path: '/admin/house',
    icon: icon('ic_analytics')
  },
  {
    title: '開發表單',
    path: '/admin/developeformlist',
    icon: icon('ic_analytics')
  },
  {
    title: '回報表單',
    path: '/admin/returnformlist',
    icon: icon('ic_analytics')
  },
];

export default AdminConfig;
