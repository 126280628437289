import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { Link,useNavigate } from 'react-router-dom';
import BusinessSearch from '../search/BusinessSearch';


const API_PATH = process.env.REACT_APP_API_PATH;

// const columnVisibilityModel = {
//   id:false
// }

export default function AdminList() {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [filterRows,setFilterRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState({
    Id : 0,
    Account : '',
    Password : '',
    AuthType: 3,
    Status:1,
    Name:'',
    Position:'',
    StoreId: 0,
  });
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'Name',
      headerName: '名子',
      width: 100,
      editable: false,
    },
    {
      field: 'Account',
      headerName: '帳號',
      width: 170,
      editable: false,
    },
    {
      field: 'Password',
      headerName: '密碼',
      sortable: false,
      width: 160,
    },
    {
      field: 'Position',
      headerName: '職位',
      width: 100,
      editable: false,
    },
    {
      field: 'StoreId',
      headerName: '分店編號',
      width: 100,
      editable: false,
    },
    {
      field: 'CreateDate',
      headerName: '創建時間',
      width: 200,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${  date.getMinutes()}`).slice(-2); 
        const hours = (`0${  date.getHours()}`).slice(-2);     
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${hours}:${minutes}`;
      }, 
    },    
    {
      field: 'AuthType',
      headerName: '權限',
      width: 110,
      editable: false,
      valueFormatter: (params) => params.value === 3 ? '管理者' : '超級管理者',
    },
    {
      field: 'Status',
      headerName: '狀態',
      width: 110,
      editable: false,
      valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'Operation',
      headerName: '操作',
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton aria-label="delete" onClick={() => handleOpenForm('edit',params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDeleteSubmit(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
  
      ),
    },
  ];

  const handleDeleteSubmit = async (id) => {
    const config = {
      headers: {
        'X-Ap-AdminId': `${sessionStorage.getItem('Id')}`,
        'X-Ap-StoreId': `${sessionStorage.getItem('StoreId')}`,
        'X-Ap-SuperToken': `${sessionStorage.getItem('Token')}`,
      }
    }; 
    try {
        const response = await axios.delete(`${API_PATH}/admin`, {
            ...config,
            params: { id }
        });

        if (response.status === 200) {
            fetchData();
        }
    } catch (error) {
        console.error("Error deleting record:", error);
        alert('請確認資料型態有無錯誤')
    }
  }
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/supergetadmin`, {
        headers: {
          'X-Ap-AdminId': `${sessionStorage.getItem('Id')}`,
          'X-Ap-StoreId': `${sessionStorage.getItem('StoreId')}`,
          'X-Ap-SuperToken': `${sessionStorage.getItem('Token')}`,
        }
      });
  
      if (response.status === 200) { 
        setRows(response.data);
        setFilterRows(response.data);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // Unauthorized
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };
  const handleSubmit = async () => {
    const config = {
      headers: {
        'X-Ap-AdminId': `${sessionStorage.getItem('Id')}`,
        'X-Ap-StoreId': `${sessionStorage.getItem('StoreId')}`,
        'X-Ap-SuperToken': `${sessionStorage.getItem('Token')}`,
      }
    };     
    try {
        const response = await axios.post(`${API_PATH}/superadmin`, request, config);
        if (response.status === 200) {
          alert('成功');
          handleCloseForm();
          fetchData();
        }
    } catch (error) {
        console.error("Error logging in:", error);
        alert('請確認資料型態及分店編號是否存在')
    }                    
  }
  const handleOpenForm = async (model,row) => {
    if(model === 'create'){
      setRequest({
        Id : 0,
        Account : '',
        Password : '',
        AuthType: 3,
        Status:1,
        Name:'',
        Position:'',
        StoreId: 0,
      });
    }
    if(model === 'edit'){
      setRequest({
        Id : row.id,
        Account :row.Account,
        Password : row.Password,
        AuthType:row.AuthType,
        Status:row.Status,
        Name:row.Name,
        Position:row.Position,
        StoreId: row.StoreId,
      });
    }
    setOpen(true);
  };

  const handleCloseForm = async () => {
    setOpen(false);
  };

  const handleInputChange = (event, propertyName) => {
    let value = event.target.value;
    if(propertyName === 'StoreId' && value !== null && value !== undefined && value !== '') {
      value = parseInt(value,10);
      console.log(value)
    }

    setRequest((prevData) => ({
      ...prevData,
      [propertyName]: value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []); 


  return (
    <>
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                    管理者列表 
                </Typography>
            </Grid>
            <BusinessSearch rows={rows} setFilterRows={setFilterRows}/>
            <Button onClick={() =>handleOpenForm('create')}>新增管理者</Button>  
      </Grid>
      <DataGridPro
        rows={filterRows}
        columns={columns}
        // columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
      />
    </Box>
    <Dialog open={open} 
          BackdropProps={{
            style: {
              backgroundColor: 'transparent',
            },
          }}
          PaperProps={{
            style: {
              width: '300px',
            },
          }}
      >
        <DialogTitle style={{justifyContent:'center',display:'flex',fontSize:'16px'}}>{request.Id === 0?'新增管理者資料':'修改管理者資料'}</DialogTitle>
        <DialogContent>
            <Box sx={{ flexGrow: 0 }}>
                <Grid container spacing={2}>.                  
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="管理者名稱"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Name}
                        onChange={(e) => handleInputChange(e, 'Name')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="職位"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Position}
                        onChange={(e) => handleInputChange(e, 'Position')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="帳號"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Account}
                        onChange={(e) => handleInputChange(e, 'Account')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="分店編號(請參考分店編號 僅能輸入數字)"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.StoreId}
                        onChange={(e) => handleInputChange(e, 'StoreId')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="密碼"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Password}
                        onChange={(e) => handleInputChange(e, 'Password')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex',marginRight:'10px' }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">狀態</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={request.Status}
                          label="Age"
                          onChange={(e) => handleInputChange(e, 'Status')}
                        >
                          <MenuItem value={1}>啟用</MenuItem>
                          <MenuItem value={0}>停用</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                </Grid>
            </Box> 
        </DialogContent>
        <DialogActions>
              <Button onClick={handleSubmit}>送出</Button>  
              <Button onClick={handleCloseForm}>取消</Button>  
        </DialogActions>
      </Dialog>
    </>
  );
}