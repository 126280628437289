import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import { ZipCodeTW } from 'zipcode-tw-react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Link,useNavigate } from 'react-router-dom';
import HouseSearch from '../search/HouseSearch';


const API_PATH = process.env.REACT_APP_API_PATH;


export default function DevelopeForm() {
  const navigate = useNavigate();
  const [customMemo,setCustomMemo] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customPattern, setCustomPattern] = useState('');
  const [customFee,setCustomFee] = useState('');
  const [request, setRequest] = useState({
    Water:'',
    Url: '',
    EntrustStatus: 1,
    ServiceFee: 0,
    Important: '',
    Name:'',
    Phone:'',
    District:'',
    Address:'',
    Rent:0,
    ContainFee:'',
    Pattern:'',
    Area:0,
    HouseType:'',
    Now:'',
    Electricity:'',
    MaintainFee:'',
    Fire:'',
    Pet:'',
    HouseName:'已廢除欄位',
    TotalHigh:'',
    DevelopeDate: new Date().toISOString().split('T')[0],
    checkboxGroup1: [],
    checkboxGroup2: [],
  });
  const handleRadioChange = (e) => {
    console.log(e.target.value)
    handleNormalInputChange(e, 'Pattern');
    setShowCustomInput(e.target.value === '其他');
  };

  const handleCustomInputChange = (e) => {
    setCustomPattern(e.target.value);
  };
  const postForm = async () => {
    try {
      // 更新 request 對象
      request.Important = request.checkboxGroup1.join(',');
      request.ContainFee = request.checkboxGroup2.join(',');
      if (request.checkboxGroup1.includes('其他')) {
        request.checkboxGroup1.push(customMemo);
        request.Important = request.checkboxGroup1.join(',');
      }

      if (request.ServiceFee === 4) {

        request.ServiceFee = customFee;
      }

      if(showCustomInput) {
        request.Pattern = customPattern;
      }
      // 構建完整的請求 URL
      const requestUrl = `${API_PATH}/house/developeform`;
  
      // 發送請求
      const response = await axios.post(requestUrl, request, {
        headers: {
          'X-Ap-Id': sessionStorage.getItem('Id'),
          'X-Ap-StoreId': sessionStorage.getItem('StoreId'),
          'X-Ap-Name': encodeURIComponent(sessionStorage.getItem('Name')),
          'X-ApStaff-Token': sessionStorage.getItem('Token'),
        }
      });
  
      // 處理響應
      if (response.status === 200) {
        // 處理成功的響應
        console.log('成功:', response.data);
        alert('送出成功')
      } else {
        // 處理非 200 的響應
        console.warn('響應非 200:', response);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // 未授權
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };

  const handleInputChange = (event, group) => {
    const { checked, name } = event.target;
    const checkboxField = group === 'group1' ? 'checkboxGroup1' : 'checkboxGroup2';
  
    setRequest(prevState => {
      const newCheckboxes = checked 
        ? [...prevState[checkboxField], name]
        : prevState[checkboxField].filter(item => item !== name);
  
      return { ...prevState, [checkboxField]: newCheckboxes };
    });
  };
  const handleBackClick = () => {
    navigate(-1); // 使用 navigate(-1) 來返回上一頁
  };
  const handleNormalInputChange = (event, propertyName) => {
    const value = event.target.value;
    setRequest((prevData) => ({
      ...prevData,
      [propertyName]: value,
    }));
  };
  // useEffect(() => {
  //   fetchData();
  // }, []); 
  const concatenatedCheckboxesGroup1 = request.checkboxGroup1.join(',');
  const concatenatedCheckboxesGroup2 = request.checkboxGroup2.join(',');

  return (
    <>
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      
    <Box sx={{ flexGrow: 0 }}>
                <Grid container spacing={2}>     
                <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <Button variant="text" onClick={handleBackClick}>上一頁</Button>
                    </Grid>            
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="網址"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Url}
                        onChange={(e) => handleNormalInputChange(e, 'Url')}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">委託狀態</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={request.EntrustStatus}
                          label="Age"
                          onChange={(e) => handleNormalInputChange(e, 'EntrustStatus')}
                        >
                          <MenuItem value={1}>成功</MenuItem>
                          <MenuItem value={0}>其他</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">服務費</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={request.ServiceFee}
                          label="Age"
                          onChange={(e) => handleNormalInputChange(e, 'ServiceFee')}
                        >
                          <MenuItem value={0}>1/2</MenuItem>
                          <MenuItem value={1}>1/3</MenuItem>
                          <MenuItem value={2}>1/4</MenuItem>
                          <MenuItem value={3}>0</MenuItem>
                          <MenuItem value={4}>其他</MenuItem>
                        </Select>
                      </FormControl>
                      {request.ServiceFee ===  4 && (
                          <TextField
                            label="服務費"
                            variant="outlined"
                            fullWidth
                            value={customFee}
                            onChange={(e) => setCustomFee(e.target.value)}
                          />
                        )}
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                    <FormControl fullWidth>
                    <FormGroup>
                    <Grid container spacing={2}>
                      {['可租補', '可入戶', '可報稅', '可營登', '可工作室', '垃圾代收', '管理員', '獨立洗衣機', '獨立陽台', '新裝潢', '有鑰匙', '其他'].map((option) => (
                        <Grid item xs={6} sm={4} md={3} key={option}>
                          <FormControlLabel 
                            control={
                              <Checkbox 
                                checked={request.checkboxGroup1.includes(option)} 
                                onChange={(e) => handleInputChange(e, 'group1')} 
                                name={option}
                              />
                            } 
                            label={option} 
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                  {request.checkboxGroup1.includes('其他') && (
                          <TextField
                            label="自定義"
                            variant="outlined"
                            fullWidth
                            value={customMemo}
                            onChange={(e) => setCustomMemo(e.target.value)}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="姓名"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Name}
                        onChange={(e) => handleNormalInputChange(e, 'Name')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="電話"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Phone}
                        onChange={(e) => handleNormalInputChange(e, 'Phone')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="區域"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.District}
                        onChange={(e) => handleNormalInputChange(e, 'District')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="地址"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Address}
                        onChange={(e) => handleNormalInputChange(e, 'Address')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="租金"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Rent}
                        onChange={(e) => handleNormalInputChange(e, 'Rent')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="總樓層"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.TotalHigh}
                        onChange={(e) => handleNormalInputChange(e, 'TotalHigh')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                      <FormControl fullWidth>
                        <FormGroup>
                          <Grid container spacing={2}>
                            {['管理費', '清潔費', '第四台', '網路', '水費', '電費', '瓦斯費', '車位費'].map((option) => (
                              <Grid item xs={6} sm={4} md={3} key={option}>
                                <FormControlLabel 
                                  control={
                                    <Checkbox 
                                      checked={request.checkboxGroup2.includes(option)} 
                                      onChange={(e) => handleInputChange(e, 'group2')} 
                                      name={option}
                                    />
                                  } 
                                  label={option} 
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </FormGroup>

                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">格局</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => handleRadioChange(e)}
                        >
                          <FormControlLabel value="雅房" control={<Radio />} label="雅房" />
                          <FormControlLabel value="套房" control={<Radio />} label="套房" />
                          <FormControlLabel value="3房1廳1衛" control={<Radio />} label="3房1廳1衛" />
                          <FormControlLabel value="3房2廳2衛" control={<Radio />} label="3房2廳2衛" />
                          <FormControlLabel value="3房2廳1衛" control={<Radio />} label="3房2廳1衛" />
                          <FormControlLabel value="2房1廳1衛" control={<Radio />} label="2房1廳1衛" />
                          <FormControlLabel value="2房2廳1衛" control={<Radio />} label="2房2廳1衛" />
                          <FormControlLabel value="其他" control={<Radio />} label="其他" />
                        </RadioGroup>
                        {showCustomInput && (
                          <TextField 
                            label="自定義格局" 
                            size='small'
                            value={customPattern} 
                            onChange={handleCustomInputChange} 
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="坪數"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Area}
                        onChange={(e) => handleNormalInputChange(e, 'Area')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">型態</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => handleNormalInputChange(e, 'HouseType')}
                        >
                          <FormControlLabel value="電梯大樓" control={<Radio />} label="電梯大樓" />
                          <FormControlLabel value="公寓" control={<Radio />} label="公寓" />
                          <FormControlLabel value="透天厝" control={<Radio />} label="透天厝" />
                          <FormControlLabel value="店面" control={<Radio />} label="店面" />
                          <FormControlLabel value="華廈" control={<Radio />} label="華廈" />
                        </RadioGroup>
                        
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">現況</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => handleNormalInputChange(e, 'Now')}
                        >
                          <FormControlLabel value="雅房" control={<Radio />} label="雅房" />
                          <FormControlLabel value="分租套房" control={<Radio />} label="分租套房" />
                          <FormControlLabel value="獨立套房" control={<Radio />} label="獨立套房" />
                          <FormControlLabel value="整層住家" control={<Radio />} label="整層住家" />
                          <FormControlLabel value="辦公室" control={<Radio />} label="辦公室" />
                          <FormControlLabel value="店面" control={<Radio />} label="店面" />
                          <FormControlLabel value="住辦" control={<Radio />} label="住辦" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="電費"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Electricity}
                        onChange={(e) => handleNormalInputChange(e, 'Electricity')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="水費"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Water}
                        onChange={(e) => handleNormalInputChange(e, 'Water')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="管理費"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.MaintainFee}
                        onChange={(e) => handleNormalInputChange(e, 'MaintainFee')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">開伙</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => handleNormalInputChange(e, 'Fire')}
                        >
                          <FormControlLabel value="可" control={<Radio />} label="可" />
                          <FormControlLabel value="可電" control={<Radio />} label="可電" />
                          <FormControlLabel value="不可" control={<Radio />} label="不可" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">寵物</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => handleNormalInputChange(e, 'Pet')}
                        >
                          <FormControlLabel value="可" control={<Radio />} label="可" />
                          <FormControlLabel value="可電" control={<Radio />} label="可貓" />
                          <FormControlLabel value="不可" control={<Radio />} label="不可" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="案名"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.HouseName}
                        onChange={(e) => handleNormalInputChange(e, 'HouseName')}
                        />
                    </Grid> */}
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="開發日期"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.DevelopeDate}
                        onChange={(e) => handleNormalInputChange(e, 'DevelopeDate')}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <Button variant="text" onClick={postForm}>送出</Button>
                    </Grid>
                </Grid>
            </Box> 
    </Box>
    </>
  );
}


// function Filter() {
//   const [open, setOpen] = useState(false);

//   const handleCloseForm = async () => {
//     setOpen(false);
//   };
//   const handleOpenForm = async () => {
//     setOpen(true);
//   };
//   return (
//   );
// }