import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { Link,useNavigate } from 'react-router-dom';
import BusinessSearch from '../search/BusinessSearch';


const API_PATH = process.env.REACT_APP_API_PATH;

// const columnVisibilityModel = {
//   id:false
// }

export default function DevelopeFormList() {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [filterRows,setFilterRows] = useState([]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'Url',
      headerName: '網址',
      width: 100,
      editable: false,
    },
    {
      field: 'Pattern',
      headerName: '格局',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'HouseType',
      headerName: '房屋型態',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'District',
      headerName: '行政區',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 3 ? '管理者' : '超級管理者',
    },
    {
      field: 'Address',
      headerName: '地址',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'TotalHigh',
      headerName: '總樓層',
      width: 60,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'Area',
      headerName: '坪數',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'Fire',
      headerName: '開火',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'Pet',
      headerName: '寵物',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'Rent',
      headerName: '租金',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'MaintainFee',
      headerName: '管理費',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'ContainFee',
      headerName: '包含費用',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'Important',
      headerName: '對話要點',
      width: 100,
      editable: false,
    },
    {
      field: 'Electricity',
      headerName: '電費',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'Name',
      headerName: '屋主名',
      width: 100,
      editable: false,
    },

    {
      field: 'Phone',
      headerName: '手機號碼',
      width: 200,
      editable: false,
    },    
    {
      field: 'ServiceFee',
      headerName: '服務費',
      sortable: false,
      width: 160,
    },
    {
      field: 'DevelopeDate',
      headerName: '開發日期',
      width: 110,
      editable: false,
      valueFormatter: (params) => {
        return params.value ? new Date(params.value).toISOString().split('T')[0] : '';
      },
    },
    {
      field: 'DeveloperName',
      headerName: '開發業務',
      width: 110,
      editable: false,
      // valueFormatter: (params) => params.value === 1 ? '啟用' : '停用',
    },
    {
      field: 'Operation',
      headerName: '操作',
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton aria-label="delete" onClick={() => handleDeleteSubmit(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
  
      ),
    },
  ];

  const handleDeleteSubmit = async (id) => {
    const config = {
      headers: {
        'X-Ap-AdminId': `${sessionStorage.getItem('Id')}`,
        'X-Ap-StoreId': `${sessionStorage.getItem('StoreId')}`,
        'X-Ap-AdminToken': `${sessionStorage.getItem('Token')}`,
      }
    }; 
    try {
        const response = await axios.delete(`${API_PATH}/developeform`, {
            ...config,
            params: { id }
        });

        if (response.status === 200) {
            alert('刪除成功');
            fetchData();
        }
    } catch (error) {
        console.error("Error deleting record:", error);
        alert('請確認資料型態有無錯誤')
    }
  }
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/house/admindevelopeform`, {
        headers: {
          'X-Ap-AdminId': `${sessionStorage.getItem('Id')}`,
          'X-Ap-StoreId': `${sessionStorage.getItem('StoreId')}`,
          'X-Ap-AdminToken': `${sessionStorage.getItem('Token')}`,
        }
      });
  
      if (response.status === 200) { 
        setRows(response.data);
        setFilterRows(response.data);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // Unauthorized
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []); 


  return (
    <>
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                    開發表單 
                </Typography>
            </Grid>
      </Grid>
      <DataGridPro
        rows={filterRows}
        columns={columns}
        // columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
      />
    </Box>
    </>
  );
}