import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------
const API_PATH = process.env.REACT_APP_API_PATH;


export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [acc,setAcc] = useState('');
  const [pw,setPw] = useState('');


  const handleLogin = async () => {
    try {
      const response = await axios.post(`${API_PATH}/login/verify`, { Account: acc, Password: pw });
      
      if (response.status === 200) {
        sessionStorage.setItem('Token', response.data.Token);
        sessionStorage.setItem('AuthType', response.data.AuthType);
        sessionStorage.setItem('StoreId', response.data.StoreId);
        sessionStorage.setItem('Name', response.data.Name);
        sessionStorage.setItem('Id', response.data.Id);
        switch(response.data.AuthType)
        {
          case (-1):
            alert('帳號密碼錯誤')
            break;
          case (0):
            alert('帳號已被停用')
            break;          
          case (1):
            navigate('/search/house', { replace: true })
            break;
          case (2):
            navigate('/search/house', { replace: true })
            break;
          case (3):
            navigate('/admin/businessmans', { replace: true })
            break;  
          case (4):
            navigate('/super/admins', { replace: true })
            break;
          default:
            navigate('/login', { replace: true });
        }
        
      } else {
        alert('發生错误');
      }
    } catch (error) {
      console.error('Error:', error);
  
      if (error.response && error.response.status === 401) {
        alert('帐密错误');
      } else {
        alert('發生错误');
      }
    }
  };
  
  

  return (
    <>
      <Stack spacing={3}>
        <TextField name="account" label="Account" onChange={(e)=>setAcc(e.target.value)}/>

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=>setPw(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleLogin();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" >
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin} style={{marginTop:'10%'}}>
        Login
      </LoadingButton>
    </>
  );
}
