import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import { ZipCodeTW } from 'zipcode-tw-react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Link,useNavigate } from 'react-router-dom';
import HouseSearch from '../search/HouseSearch';


const API_PATH = process.env.REACT_APP_API_PATH;

const columnVisibilityModel = {
  id:false
}

export default function StaffHouseList() {
  LicenseInfo.setLicenseKey('9af075c09b5df7441264261f25d3d08eTz03ODI4MyxFPTE3MzEwNzgzMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [filterRows,setFilterRows] = useState([]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'ObjectStatus',
      headerName: '物件狀態',
      width: 150,
      editable: false,
    },
    {
      field: 'ObjectNo',
      headerName: '物件編號',
      sortable: false,
      width: 100,
    },    
    {
      field: 'ObjectName',
      headerName: '物件名稱',
      sortable: false,
      width: 100,
    },
    {
      field: 'BusinessPersonName',
      headerName: '業務名稱',
      sortable: false,
      width: 100,
    },
    {
      field: 'Dialogue',
      headerName: '對話',
      sortable: false,
      width: 100,
    },
    {
      field: 'Landlord',
      headerName: '房東',
      sortable: false,
      width: 100,
    },
    {
      field: 'Phone',
      headerName: '房東手機',
      sortable: false,
      width: 100,
    },
    {
      field: 'City',
      headerName: '縣市',
      sortable: false,
      width: 100,
    },
    {
      field: 'District',
      headerName: '行政區',
      sortable: false,
      width: 100,
    },
    {
      field: 'Address',
      headerName: '地址',
      sortable: false,
      width: 100,
    },
    {
      field: 'Rent',
      headerName: '租金',
      sortable: false,
      width: 100,
    },
    {
      field: 'ContainService',
      headerName: '含',
      sortable: false,
      width: 100,
    },
    {
      field: 'Pattern',
      headerName: '格局',
      sortable: false,
      width: 100,
    },
    {
      field: 'Area',
      headerName: '坪數',
      sortable: false,
      width: 100,
    },
    {
      field: 'Type',
      headerName: '型態',
      sortable: false,
      width: 100,
    },
    {
      field: 'Situations',
      headerName: '現況',
      sortable: false,
      width: 100,
    },
    {
      field: 'Electricity',
      headerName: '電費',
      sortable: false,
      width: 100,
    },
    {
      field: 'ManagementFee',
      headerName: '管理費',
      sortable: false,
      width: 100,
    },
    {
      field: 'OpenFire',
      headerName: '可否開伙',
      sortable: false,
      width: 100,
      valueFormatter: (params) => params.value === 1? '可':'否', 
    },
    {
      field: 'Pet',
      headerName: '可否寵物',
      sortable: false,
      width: 100,
      valueFormatter: (params) => params.value === 1? '可':'否', 
    },
    {
      field: 'ValidDate',
      headerName: '有效日期',
      width: 200,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${  date.getMinutes()}`).slice(-2); 
        const hours = (`0${  date.getHours()}`).slice(-2);     
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      }, 
    },
    {
      field: 'Web591Url',
      headerName: '591網址',
      sortable: false,
      width: 100,
    },
    {
      field: 'DevelopmentPersonName',
      headerName: '開發員名稱',
      width: 110,
      editable: false,
    },
    {
      field: 'ServiceFee',
      headerName: '服務費',
      width: 110,
      editable: false,
    },
    {
      field: 'Web591Date',
      headerName: '591上架日',
      width: 100,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${  date.getMinutes()}`).slice(-2); 
        const hours = (`0${  date.getHours()}`).slice(-2);     
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      }, 
    },
    {
      field: 'RentSignDate',
      headerName: '簽約日',
      width: 100,
      editable: false,
      valueFormatter: (params) => { 
        const date = new Date(params.value || new Date());
        const minutes = (`0${  date.getMinutes()}`).slice(-2); 
        const hours = (`0${  date.getHours()}`).slice(-2);     
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} `;
      }, 
    },
    {
      field: 'ObjectEvaluate',
      headerName: '案件評估',
      width: 110,
      editable: false,
    },
    {
      field: 'Memo',
      headerName: '備註',
      width: 110,
      editable: false,
    }
  ];

  const handleBackClick = () => {
      navigate('/search/house');
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/house/excelhouse`, {
        headers: {
          'X-Ap-Id': sessionStorage.getItem('Id'),
          'X-Ap-StoreId': sessionStorage.getItem('StoreId'),
          'X-Ap-Name': encodeURIComponent(sessionStorage.getItem('Name')),
          'X-ApStaff-Token': sessionStorage.getItem('Token'),
        }
      });
  
      if (response.status === 200) { 
        setRows(response.data);
        setFilterRows(response.data);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // Unauthorized
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };


  useEffect(() => {
    fetchData();
  }, []); 


  return (
    <>
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      <Grid container spacing={2} style={{marginBottom:'1%'}}>
        <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
            <Typography variant="h2" component="h2">
                物件列表 
            </Typography>
            <Button onClick={handleBackClick}>上一頁</Button>  
        </Grid>        
      </Grid>          
      <DataGridPro
        rows={filterRows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100,75,50]}
        disableRowSelectionOnClick
      />
    </Box>
    </>
  );
}


// function Filter() {
//   const [open, setOpen] = useState(false);

//   const handleCloseForm = async () => {
//     setOpen(false);
//   };
//   const handleOpenForm = async () => {
//     setOpen(true);
//   };
//   return (
//   );
// }