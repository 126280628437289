import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import { useState,useRef,useEffect,useCallback} from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Alert, useMediaQuery } from '@mui/material';
import * as React from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ZipCodeTW } from 'zipcode-tw-react';
import TodayIcon from '@mui/icons-material/Today';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PageDeviceError from '../pages/PageDeviceError';
import { useZipCode } from '../context/ZipCodeContext';

const API_PATH = process.env.REACT_APP_API_PATH;





export default function SearchHouse() {
    const [rows, setRows] = useState([]);
    const [detail, setDetail] = useState({});
    const { zipCodeInfo, setZipCodeInfo } = useZipCode();
    const [filter,setFilter] = useState({
        HouseName:'',
        City:'',
        District:'',
        Address:'',
        Rent:null,
        BusinessName:'',
        DeveloperName:'',
        Start:null,
        End:null,
        Landlord:''
    });
    const [windowDimensions, setWindowDimensions] = useState({
        width: typeof window !== 'undefined' ? window.innerWidth : 0,
        height: typeof window !== 'undefined' ? window.innerHeight : 0,
    });

    const navigate = useNavigate();

    const handleExcelClick = () => {
        navigate('/excellist');
    };
    const handleDevelopeFormClick = () => {
        navigate('/developeform');
    };
    const handleReturnFormClick = () => {
        navigate('/returnform');
    };
    const handleZipCodeChange = (data) => {
        setZipCodeInfo(prev => ({
            ...prev,
            [data.countyFieldName]: data.countyValue,
            [data.districtFieldName]: data.districtValue,
            [data.zipFieldName]: data.zipValue,
        }));
        console.log(data);
    };
    const handleZipCodeNotExists = (data) => {
        alert('郵遞區號不存在');
    };
    const handleFilterChange = (event, propertyName) => {
        const value = event.target.value;
        setFilter((prevData) => ({
          ...prevData,
          [propertyName]: value,
        }));
    };



    const fetchFilterData = async () => {
        try {
          // 创建 URLSearchParams 实例
          const queryParams = new URLSearchParams();
      
          // 为每个过滤器参数添加条件
          if (zipCodeInfo.county) queryParams.append('city', zipCodeInfo.county);
          if (filter.HouseName) queryParams.append('house', filter.HouseName);
          if (zipCodeInfo.district) queryParams.append('district', zipCodeInfo.district);
          if (filter.Address) queryParams.append('address', filter.Address);
          if (filter.Rent !== null) queryParams.append('rent', parseInt(filter.Rent,10));
          if (filter.BusinessName) queryParams.append('businessMan', filter.BusinessName);
          if (filter.DeveloperName) queryParams.append('developer', filter.DeveloperName);
          if (filter.Start !== null) queryParams.append('start', filter.Start);
          if (filter.End !== null) queryParams.append('end', filter.End);
          if (filter.Landlord) queryParams.append('landlord', filter.Landlord);
      
          // 构建完整的请求 URL
          const requestUrl = `${API_PATH}/house/house?${queryParams.toString()}`;
      
          // 发送请求
          const response = await axios.get(requestUrl, {
            headers: {
              'X-Ap-Id': sessionStorage.getItem('Id'),
              'X-Ap-StoreId': sessionStorage.getItem('StoreId'),
              'X-Ap-Name': encodeURIComponent(sessionStorage.getItem('Name')),
              'X-ApStaff-Token': sessionStorage.getItem('Token'),
            }
          });
      
          // 处理响应
          if (response.status === 200) {
            setRows(response.data);
          }
        } catch (error) {
          console.error('Error fetching data: ', error);
      
          if (error.response && error.response.status === 401) {
            // Unauthorized
            navigate('/login', { replace: true });
          } else {
            alert('發生錯誤');
          }
        }
      };

    // if(!isMobile) {
    //     return(
    //         <>
    //             <PageDeviceError/>
    //         </>
    //     )
    // }
    
    
    return (       
        <>
            <Box sx={{width: '90%',minHeight: '600px', height:`${windowDimensions.height}px`,backgroundColor:'transparent',overflow:'auto',margin:'auto'}}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>          
                        <Grid item xs={12} style={{display:'flex',justifyContent:'center',marginTop:'5%'}}>      
                            <ZipCodeTW
                                displayType="text"
                                countyValue={zipCodeInfo.county}
                                districtValue={zipCodeInfo.district}
                                zipCodeValue={zipCodeInfo.zipCode}
                                handleChangeCounty={handleZipCodeChange}
                                handleChangeDistrict={handleZipCodeChange}
                                handleChangeZipCode={handleZipCodeChange}
                                handleBlurZipCode={handleZipCodeChange}
                                handleZipCodeNotExists={handleZipCodeNotExists}
                                zipCodePositionLast={false}
                            />
                        </Grid>     
                        <Grid item xs={9} style={{display:'flex',justifyContent:'center'}}>      
                            <TextField id="standard-basic" label="地址" 
                            size='small'
                            variant="standard" 
                                value={filter.Address}
                                onChange={(e) => handleFilterChange(e, 'Address')}/>
                        </Grid>
                        <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                            <TextField id="standard-basic" label="物件代號" 
                            variant="standard"   
                            size='small'
                                value={filter.DeveloperName}
                                onChange={(e) => handleFilterChange(e, 'DeveloperName')}/>
                        </Grid>  
                        <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                            <TextField id="standard-basic" label="物件名稱" 
                            variant="standard" 
                            size='small'
                            value={filter.HouseName}
                            onChange={(e) => handleFilterChange(e, 'HouseName')}/>
                        </Grid> 
                        <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                            <TextField id="standard-basic" label="租金" variant="standard"     
                            size='small'
                                value={filter.Rent === null?'':filter.Rent}
                                onChange={(e) => handleFilterChange(e, 'Rent')}/>
                        </Grid> 
                        <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                            <TextField id="standard-basic" label="業務名稱" 
                            variant="standard"     
                            size='small'
                                value={filter.BusinessName}
                                onChange={(e) => handleFilterChange(e, 'BusinessName')}/>
                        </Grid> 
                        <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                            <TextField id="standard-basic" label="房東名稱"
                             variant="standard"
                            size='small'
                                value={filter.Landlord}
                                onChange={(e) => handleFilterChange(e, 'Landlord')}/>
                        </Grid> 
                        {/* <Grid item xs={6} style={{display:'flex',justifyContent:'center'}}>      
                            <TextField id="standard-basic" label="搜尋起始日" variant="standard"                 
                                value={filter.Start=== null?'':filter.Start}
                                placeholder='YYYY-MM-DD'
                                size='small'
                                onChange={(e) => handleFilterChange(e, 'Start')}/>
                        </Grid> 
                        <Grid item xs={6} style={{display:'flex',justifyContent:'center'}}>      
                            <TextField id="standard-basic" label="搜尋截止日" variant="standard"                 
                                value={filter.End=== null?'':filter.End}
                                placeholder='YYYY-MM-DD'
                            size='small'
                                onChange={(e) => handleFilterChange(e, 'End')}/>
                        </Grid> */}
                        <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                                <Button onClick={fetchFilterData}>搜尋</Button>  
                                <Button onClick={() =>setFilter({
                                    HouseName:'',
                                    City:'',
                                    District:'',
                                    Address:'',
                                    Rent:null,
                                    BusinessName:'',
                                    DeveloperName:'',
                                    Start:null,
                                    End:null,
                                    Landlord:''
                                })}>清除搜尋條件</Button>  
                                <Button onClick={handleExcelClick}>Excel</Button>  
                                <Button onClick={handleDevelopeFormClick}>開發表</Button>  
                                <Button onClick={handleReturnFormClick}>回報表</Button>  
                        </Grid>                      
                            {rows.map((row) => (
                                 // eslint-disable-next-line react/jsx-key
                            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>     
                                <House key={row.id} {...row} />
                            </Grid> 
                            ))}                 
                    </Grid>

                </Box>                    
            </Box>
        </>
    );
}

function House(data) {
    const navigate = useNavigate();
    const handleDetailClick = () => {
        // 例如，使用本地存储来传递数据
        localStorage.setItem('currentHouseData', JSON.stringify(data));
        navigate('/house-detail');
    };
    return (
        <Box sx={{ width: '100%', backgroundColor: 'transparent', overflow: 'hidden', my: 1,borderColor: 'grey.300',border: 1,borderRadius: '4px',mb: 2, p: 2}}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <Grid container spacing={0}>
                    <Grid item xs={3}>
                        <Typography variant="h6" style={{ fontSize: 13, marginRight: '5%', flexShrink: 0 }}>
                            {data.ObjectName}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            {data.City}{data.District}{data.Address}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            現況: <Chip label={data.Situation} variant="outlined" size="small" style={{ marginLeft: '0.5rem' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            格局: <Chip label={data.Pattern} variant="outlined" size="small" style={{ marginLeft: '0.5rem' }} />
                        </Typography>
                    </Grid>                    
                    <Grid item xs={3}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            坪數: {data.Area}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            含: {data.ContainService}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            可開火: {data.OpenFire === 1 ? '可' : '不可'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            可寵: {data.Pet === 1 ? '可' : '不可'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            業務名稱: {data.BusinessPersonName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            開發人員: {data.DevelopmentPersonName}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            物件狀態: {data.ObjectStatus}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            租金: {data.Rent}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            房東: {data.Landlord} 
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            物件編號: {data.ObjectNo}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{ fontSize: 13, marginRight: '1%', flexShrink: 0 }}>
                            房東手機: {data.Phone}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body1" style={{display:'flex',justifyContent:'center'}}>
                            <Button onClick={handleDetailClick}>詳細</Button>  
                        </Typography>
                    </Grid>
                </Grid>
            </Box>                    
        </Box>
    );
}

