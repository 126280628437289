import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

import BusinessManList from './erp/BusinessManList';
import DeveloperList from './erp/DeveloperList';
import HouseList from './erp/HouseList';
import AdminList from './erp/AdminList';
import StoreList from './erp/StoreList';
import SearchHouse from './erp/SearchHouse';
import HouseDetail from './erp/HouseDetail';
import StaffHouseList from './erp/StaffHouseList';
import DevelopeFormList from './erp/DevelopeFormList';
import ReturnFormList from './erp/ReturnFormList';

import DevelopeForm from './erp/DevelopeForm';
import RetrunForm from './erp/RetrunForm';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'search/house',
      element: <SearchHouse />,
    },
    {
      path: '/house-detail',
      element: <HouseDetail />,
    },
    {
      path: '/excellist',
      element: <StaffHouseList />,
    },
    {
      path: '/developeform',
      element: <DevelopeForm />,
    },
    {
      path: '/returnform',
      element: <RetrunForm />,
    },
    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/businessmans" />, index: true },
        { path: 'businessmans', element: <BusinessManList /> },
        { path: 'developers', element: <DeveloperList /> },
        { path: 'house', element: <HouseList /> },
        { path: 'developeformlist', element: <DevelopeFormList /> },
        { path: 'returnformlist', element: <ReturnFormList /> },
      ],
    },
    {
      path: '/super',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/admins" />, index: true },
        { path: 'admins', element: <AdminList /> },
        { path: 'stores', element: <StoreList /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
