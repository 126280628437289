// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const SuperConfig = [
  {
    title: '管理者列表',
    path: '/super/admins',
    icon: icon('ic_analytics')
  },
  {
    title: '分店列表',
    path: '/super/stores',
    icon: icon('ic_analytics')
  },
];

export default SuperConfig;
