import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import { useState,useRef,useEffect,useCallback} from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Alert, useMediaQuery } from '@mui/material';
import * as React from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ZipCodeTW } from 'zipcode-tw-react';
import TodayIcon from '@mui/icons-material/Today';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PageDeviceError from '../pages/PageDeviceError';

const API_PATH = process.env.REACT_APP_API_PATH;





export default function HouseDetail() {
    const [data,setData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setData(JSON.parse(localStorage.getItem('currentHouseData')));
    }, []);

    const handleBackClick = () => {
        navigate(-1); // 使用 navigate(-1) 來返回上一頁
    };
    if(!data) {
        return(
            <>
                <Typography variant="h6" style={{ fontSize: 13, marginRight: '5%', flexShrink: 0 }}>
                    尚未找到資料
                </Typography>
            </>
        )
    }

    return (       
        <>
        <Button variant="text" onClick={handleBackClick}>
            上一頁
        </Button>
        <Box sx={{ width: '100%', backgroundColor: 'transparent', overflow: 'hidden', my: 1,marginTop:'10%' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <Grid container spacing={0}>
                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            物件狀態
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.ObjectStatus}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            物件編號
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.ObjectNo}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            開發日期
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.DevelopmentDate}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            服務費
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.ManagementFee}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            對話要點
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Dialogue}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            電話
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Phone}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            區域
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.District}
                        </Typography>
                    </Grid>
                    {/* <Divider sx={{ width: '100%', my: 1 }} /> */}

                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            地址
                        </Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Address}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            租金
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Rent}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            格局
                        </Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Pattern}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            含
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.ContainService}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            坪數
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Area}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            型態
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Type}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            現況
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Situation}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            電費
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Electricity}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            管理費
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.ManagementFee}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            開伙
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.OpenFire}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ fontSize: 13 }}>
                            可寵
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                            {data.Pet}
                        </Typography>
                    </Grid>
                    <Divider sx={{ width: '100%', my: 1 }} />
                </Grid>
            </Box>                    
        </Box>
        </>
    );
}


