import { useState,useRef,useEffect,useCallback} from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LicenseInfo,DataGridPro } from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import { ZipCodeTW } from 'zipcode-tw-react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { Link,useNavigate } from 'react-router-dom';
import HouseSearch from '../search/HouseSearch';


const API_PATH = process.env.REACT_APP_API_PATH;


export default function RetrunForm() {
  const navigate = useNavigate();
  const [customHouseStatus,setCustomHouseStatus] = useState('');
  const [request, setRequest] = useState({
    Num: '',
    District: '',
    HouseStatus: '',
    Memo: '',
  });

  const postForm = async () => {
    try {
      const requestUrl = `${API_PATH}/house/returnform`;
      if(request.HouseStatus === '自行输入'){
        request.HouseStatus = customHouseStatus;
      }
      // 發送請求
      const response = await axios.post(requestUrl, request, {
        headers: {
          'X-Ap-Id': sessionStorage.getItem('Id'),
          'X-Ap-StoreId': sessionStorage.getItem('StoreId'),
          'X-Ap-Name': encodeURIComponent(sessionStorage.getItem('Name')),
          'X-ApStaff-Token': sessionStorage.getItem('Token'),
        }
      });
  
      // 處理響應
      if (response.status === 200) {
        // 處理成功的響應
        console.log('成功:', response.data);
        alert('送出成功')
      } else {
        // 處理非 200 的響應
        console.warn('響應非 200:', response);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
  
      if (error.response && error.response.status === 401) {
        // 未授權
        navigate('/login', { replace: true });
      } else {
        alert('發生錯誤');
      }
    }
  };

  const handleBackClick = () => {
    navigate(-1); // 使用 navigate(-1) 來返回上一頁
  };
  const handleNormalInputChange = (event, propertyName) => {
    const value = event.target.value;
    setRequest((prevData) => ({
      ...prevData,
      [propertyName]: value,
    }));
  };
  // useEffect(() => {
  //   fetchData();
  // }, []); 


  return (
    <>
    <Box sx={{ height: 600, width: '90%',margin:'auto' }}>
      
    <Box sx={{ flexGrow: 0 }}>
                <Grid container spacing={2}>     
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <Button variant="text" onClick={handleBackClick}>上一頁</Button>
                    </Grid>  
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                        <TextField
                        fullWidth 
                        id="outlined-number"
                        size="small"
                        label="編號"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={request.Num}
                        onChange={(e) => handleNormalInputChange(e, 'Num')}
                        />
                    </Grid>       
                    <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">行政區</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => handleNormalInputChange(e, 'District')}
                        >
                          <FormControlLabel value="松山區" control={<Radio />} label="松山區" />
                          <FormControlLabel value="信義區" control={<Radio />} label="信義區" />
                          <FormControlLabel value="大安區" control={<Radio />} label="大安區" />
                          <FormControlLabel value="中山區" control={<Radio />} label="中山區" />
                          <FormControlLabel value="中正區" control={<Radio />} label="中正區" />
                          <FormControlLabel value="大同區" control={<Radio />} label="大同區" />
                          <FormControlLabel value="萬華區" control={<Radio />} label="萬華區" />
                          <FormControlLabel value="文山區" control={<Radio />} label="文山區" />
                          <FormControlLabel value="南港區" control={<Radio />} label="南港區" />
                          <FormControlLabel value="內湖區" control={<Radio />} label="內湖區" />
                          <FormControlLabel value="士林區" control={<Radio />} label="士林區" />
                          <FormControlLabel value="北投區" control={<Radio />} label="北投區" />
                          {/* 新北市的行政區 */}
                          <FormControlLabel value="板橋區" control={<Radio />} label="板橋區" />
                          <FormControlLabel value="新莊區" control={<Radio />} label="新莊區" />
                          <FormControlLabel value="中和區" control={<Radio />} label="中和區" />
                          <FormControlLabel value="永和區" control={<Radio />} label="永和區" />
                          <FormControlLabel value="土城區" control={<Radio />} label="土城區" />
                          <FormControlLabel value="樹林區" control={<Radio />} label="樹林區" />
                          <FormControlLabel value="三峽區" control={<Radio />} label="三峽區" />
                          <FormControlLabel value="三重區" control={<Radio />} label="三重區" />
                          <FormControlLabel value="蘆洲區" control={<Radio />} label="蘆洲區" />
                          <FormControlLabel value="五股區" control={<Radio />} label="五股區" />
                          <FormControlLabel value="泰山區" control={<Radio />} label="泰山區" />
                          <FormControlLabel value="淡水區" control={<Radio />} label="淡水區" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">案件狀態</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => handleNormalInputChange(e, 'HouseStatus')}
                        >
                          <FormControlLabel value="案件還在" control={<Radio />} label="案件還在" />
                          <FormControlLabel value="出租" control={<Radio />} label="出租" />
                          <FormControlLabel value="屋主收訂" control={<Radio />} label="屋主收訂" />
                          <FormControlLabel value="暫時關閉" control={<Radio />} label="暫時關閉" />
                          <FormControlLabel value="未接" control={<Radio />} label="未接" />
                          <FormControlLabel value="屋主要求下架" control={<Radio />} label="屋主要求下架" />
                          <FormControlLabel value="自行输入" control={<Radio />} label="自行输入" />
                        </RadioGroup>
                        {request.HouseStatus === '自行输入' && (
                          <TextField
                            label="輸入案件狀態"
                            variant="outlined"
                            fullWidth
                            value={customHouseStatus}
                            onChange={(e) => setCustomHouseStatus(e.target.value)}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{ justifyContent: 'center', display: 'flex' }}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">業務回饋</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => handleNormalInputChange(e, 'Memo')}
                        >
                          <FormControlLabel value="還在可帶看" control={<Radio />} label="還在可帶看" />
                          <FormControlLabel value="被要求下架" control={<Radio />} label="被要求下架" />
                          <FormControlLabel value="屋主收訂先關閉" control={<Radio />} label="屋主收訂先關閉" />
                          <FormControlLabel value="已出租下架" control={<Radio />} label="已出租下架" />
                          <FormControlLabel value="未接" control={<Radio />} label="未接" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }} style={{justifyContent:'center',display:'flex' }}>
                      <Button variant="text" onClick={postForm}>送出</Button>
                    </Grid>
                </Grid>
            </Box> 
    </Box>
    </>
  );
}


// function Filter() {
//   const [open, setOpen] = useState(false);

//   const handleCloseForm = async () => {
//     setOpen(false);
//   };
//   const handleOpenForm = async () => {
//     setOpen(true);
//   };
//   return (
//   );
// }